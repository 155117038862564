import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import Content from '../components/Content/Content'
import { StaticImage } from 'gatsby-plugin-image'
import * as LeaderStyles from '../components/Leader/Leader.module.scss'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IAwardsQueryData } from '../graphql/queries/AwardsQuery'
import { MuddyAwardsContext, MuddyAwardsStates } from '../lib/utils'
import AwardsMenu from '../components/MuddyAwards/AwardsMenu'
import { AWARDS_COUNTY_INFO } from '../lib/data/Awards'
import AwardsHeader from '../components/MuddyAwards/AwardsHeader'
import CTARow from '../components/CTA/CTARow'
import BlocksWrappers from '../components/Blocks/BlocksWrappers'
import { SponsoredCategories } from '../types'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
        uri
        parent {
          node {
            uri
          }
        }
      }
      sponsoredCategories {
        slug
        title
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

type AwardsIntroProps = {
  data: IAwardsQueryData
}

const AwardsIntro = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      page,
      category,
      sponsoredCategories,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      }
    }
  }
}: AwardsIntroProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const [trendingCat, setTrendingCat] = useState<
    SponsoredCategories | undefined
  >()
  const [pageTitle, setPageTitle] = useState<string>(
    'Welcome to the Muddy Awards 2024!'
  )

  useEffect(() => {
    const trending = sponsoredCategories.filter(c => c.slug === 'SPOTLIGHT')

    trending.length > 0 && setTrendingCat(trending[0])
  }, [sponsoredCategories])

  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  useEffect(() => {
    if (AwardsContext === MuddyAwardsStates.VOTING) {
      setPageTitle('The Muddy Awards Regional Finals 2024 are now open!')
    } else if (AwardsContext === MuddyAwardsStates.VOTING_CLOSED) {
      setPageTitle('The Muddy Awards Regional Finals 2024 are now closed.')
    } else if (
      AwardsContext === MuddyAwardsStates.WINNERS ||
      AwardsContext === MuddyAwardsStates.NATIONAL_WINNERS
    ) {
      setPageTitle('THE RESULTS ARE IN!')
    } else if (AwardsContext === MuddyAwardsStates.NATIONAL_FINALISTS) {
      setPageTitle('Meet your 2024 National Muddy Awards Finalists!')
    }
  }, [AwardsContext])

  return (
    <div>
      <PageMeta
        pageData={{
          ...generalSettings,
          url: `https://dev.muddystilettos.co.uk/${
            generalSettings.siteId !== 1 && `${countyInfo.siteSlug}/`
          }${page.uri}`,
          title: 'Muddy Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image: 'https://muddystilettos.co.uk/images/awards/Leader_2023.jpg'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      {/* <Leaderboard adSlot={'TopLB'} sticky /> */}
      <div className={LeaderStyles.AwardsLeaderImage}>
        <StaticImage
          src={`../../static/images/awards/Leader_2023.jpg`}
          alt="Muddy Awards 2024"
          style={{ height: '600px', width: '100%' }}
          objectFit={`cover`}
        />
      </div>
      <AwardsHeader category={category} generalSettings={generalSettings} />
      <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      <div>
        <Content>
          <h1>{pageTitle}</h1>
          {/* <p>
            <img
              src={`http://dev.muddystilettos.co.uk/wp-content/uploads/2024/02/Nominate-Now_1000x226.gif`}
              style={{ maxWidth: '100%' }}
            />
          </p> */}
          {AwardsContext === MuddyAwardsStates.NATIONAL_FINALISTS ? (
            <>
              <p>
                Boom! Release the confetti cannon for the 2024 National Muddy
                Awards Finalists, in association with{' '}
                <a
                  href="https://go.skimresources.com/?id=122679X1583220&isjs=1&jv=15.7.1&sref=https%3A%2F%2Fbucksoxon.muddystilettos.co.uk%2Fawards%2Fwinners%2F&url=https%3A%2F%2Fwww.tide.co%2Foffers%2Fmuddystilettosawards%2F&xs=1&xtz=-60&xuuid=085d008a6c5c2b83712fe03edc9135fc&xjsf=other_click__contextmenu%20%5B2%5D"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  Tide
                </a>
                . Our expert editorial team has pitched, deliberated and arm
                wrestled to bring you the ‘Best of the Best’ indie businesses
                across all 28 Muddy counties. Sashay this way and meet the 105
                finalists across the 21 Muddy Awards lifestyle categories.
              </p>
              <p>
                All Regional Winners were automatically put through for
                consideration into our prestigious National Finals – and they’re
                all incredible and worthy of a visit. Check out which of your
                Regional Winners have made the cut to become{' '}
                <a href="https://muddystilettos.co.uk/awards/national-results/">
                  NATIONAL FINALISTS
                </a>
                .
              </p>
              <p>
                A massive THANK YOU for taking the time to support your awesome
                local businesses this year – it‘s been our biggest Awards ever!
                And, whilst we wish every brilliant business nominated and voted
                for could make it through, unfortunately that’s not how
                competitions work!
              </p>

              <p>
                One winner per category will be crowned on{' '}
                <strong>11 July 2024</strong>, so put the Champagne on ice and
                let’s celebrate every single win. Paracetamol on standby,
                people.
              </p>
              {/* <p>
                <strong style={{ textTransform: 'uppercase' }}>
                  What happens next
                </strong>
              </p>
              <p>
                <strong>NATIONAL WINNERS (11 JULY 2024)</strong>
              </p>
              <p>
                The Muddy Stilettos Editors will decide who is <u>the</u> ‘Best
                of the Best’ in each category, announcing the National Winners
                on 11 JULY 2024.
              </p> */}
            </>
          ) : (
            <>
              {AwardsContext === MuddyAwardsStates.NATIONAL_WINNERS ? (
                <>
                  <p>
                    It’s the moment you’ve been waiting for. Pop the Champagne
                    corks and confetti cannons, we are delighted to announce
                    2024’s ‘Best of the Best’ National Muddy Awards winners, in
                    association with{` `}
                    <a
                      href="https://go.skimresources.com/?id=122679X1583220&isjs=1&jv=15.7.1&sref=https%3A%2F%2Fbucksoxon.muddystilettos.co.uk%2Fawards%2Fwinners%2F&url=https%3A%2F%2Fwww.tide.co%2Foffers%2Fmuddystilettosawards%2F&xs=1&xtz=-60&xuuid=085d008a6c5c2b83712fe03edc9135fc&xjsf=other_click__contextmenu%20%5B2%5D"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      Tide
                    </a>
                    !
                  </p>
                  <p>
                    Our experienced judging panel of Muddy Editors across 28
                    counties have been wrangling for days to determine which
                    indie businesses stood head and shoulders above the
                    competition. It has been an incredibly difficult decision,
                    all the National Finalists are fabulous, but there can only
                    be one winner in each of the 21 lifestyle categories.
                  </p>
                  <p>
                    Ready to meet Muddy’s ‘Best of the Best’? Find the{' '}
                    <a href="https://muddystilettos.co.uk/awards/national-results/">
                      NATIONAL WINNERS here
                    </a>
                    .
                  </p>
                  <p>
                    Looking for something more local, find your{' '}
                    <Link
                      to={
                        generalSettings.siteId === 1
                          ? `/awards/counties/`
                          : `/awards/winners/`
                      }
                    >
                      REGIONAL WINNERS here
                    </Link>
                    .
                  </p>
                  <p>
                    The ethos of Muddy Stilettos is all about celebrating and
                    supporting the most unique and brilliant local businesses,
                    and we hope you will raise a toast to all the Winners.
                  </p>
                  <p>
                    A massive THANK YOU for taking the time to support your
                    awesome local businesses this year – it‘s been our biggest
                    Awards ever! We wish every brilliant business nominated and
                    voted for could make it through, but for those of you who
                    didn’t win, please come back again – it could be your year
                    in 2025!
                  </p>
                </>
              ) : (
                <>
                  {AwardsContext === MuddyAwardsStates.WINNERS ? (
                    <>
                      <p>
                        You&apos;ve voted, we&apos;ve counted (and counted…) and
                        the Muddy Awards 2024 results are in! Reaching an
                        astonishing 10 million people in 8 weeks, over 515,000
                        votes and nominations were cast nationally, making it
                        the most popular Muddy Awards to date. I know, bonkers!
                      </p>
                      <p>
                        Find out who your{' '}
                        {generalSettings.siteId === 1 && `regional `}
                        winners are by visiting{' '}
                        {generalSettings.siteId === 1 ? `your` : `the`}{' '}
                        <Link
                          to={
                            generalSettings.siteId === 1
                              ? `/awards/counties/`
                              : `/awards/winners`
                          }
                        >
                          {generalSettings.siteId === 1
                            ? `COUNTY SITE`
                            : `REGIONAL WINNERS`}
                        </Link>
                        {` `}
                        page.
                      </p>
                      <p>
                        The Muddy Stilettos Awards are the most coveted awards
                        for small independent lifestyle businesses across the
                        country, now across a whopping 28 counties and, as
                        always, are totally FREE to enter from start to finish.
                      </p>
                      <p>
                        A massive THANK YOU for taking the time to support your
                        awesome local businesses this year - it&apos;s been our
                        biggest Awards ever! And, whilst we wish every brilliant
                        business nominated and voted for could make it through,
                        we hope you will join us in congratulating all the
                        Regional Winners - and cue a chorus of Champagne corks
                        popping across the{' '}
                        {generalSettings.siteId === 1 ? `country` : `county`}!
                      </p>

                      <p>
                        <strong style={{ textTransform: 'uppercase' }}>
                          What happens next
                        </strong>
                      </p>
                      <p>
                        <strong>NATIONAL FINALS (3 JULY- 11 JULY 2024)</strong>
                      </p>
                      <p>
                        Regional Winners from every category will now
                        automatically go through to our prestigious National
                        Finals. The Muddy Stilettos Editors will judge who is
                        the ‘Best of the Best’ in each category, announcing the
                        National Winners on 11 JULY 2024.
                      </p>
                      <p>
                        Keep your peepers peeled for when we announce the
                        National Finalists in the next few weeks!{' '}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        The Muddy Stilettos Awards are the most coveted awards
                        for independent lifestyle businesses, running across 28
                        counties and totally FREE to enter from start to finish.
                      </p>

                      <p>
                        Run with positivity and a sense of fun, the Muddy Awards
                        offer our 3.8 million readers a chance to say a massive
                        THANK YOU to their favourite local businesses.
                      </p>

                      <div>
                        {AwardsContext === MuddyAwardsStates.VOTING_CLOSED ? (
                          <p>
                            The Regional Awards are now closed, and we are now
                            counting and verifying the votes.
                          </p>
                        ) : (
                          <>
                            {AwardsContext ===
                            MuddyAwardsStates.NOMINATIONS_CLOSED ? (
                              <>
                                <p style={{ color: 'rgb(231,44,94)' }}>
                                  <strong>Nominations are now closed.</strong>
                                  {` `}
                                  We&apos;re busy counting and verifying your
                                  nominations so watch this space - Finalists
                                  will be announced and voting will open on{' '}
                                  <strong>3 April 2024</strong>. Good luck,
                                  everyone!
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  It takes just seconds to show your support,
                                  and you&apos;ll also be in with a chance to{' '}
                                  <a
                                    href="https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    win a two-night stay at The Pig Hotel, worth
                                    £1,250
                                  </a>
                                  .
                                </p>
                              </>
                            )}
                          </>
                        )}

                        {AwardsContext === MuddyAwardsStates.VOTING && (
                          <>
                            <p>
                              <Link
                                className={LeaderStyles.Button}
                                style={{ width: 'fit-content' }}
                                to={
                                  generalSettings.siteId === 1
                                    ? `/awards/counties`
                                    : `/awards/categories`
                                }
                              >
                                Click here to start voting
                              </Link>
                            </p>
                          </>
                        )}

                        {AwardsContext === MuddyAwardsStates.VOTING_CLOSED ? (
                          <p>
                            <strong style={{ textTransform: 'uppercase' }}>
                              What happens next
                            </strong>
                          </p>
                        ) : (
                          <p>Here&apos;s how it works:</p>
                        )}
                        <p>
                          <img
                            src={`http://dev.muddystilettos.co.uk/wp-content/uploads/2024/02/Dates_1000x226.gif`}
                            style={{ maxWidth: '100%' }}
                          />
                        </p>
                        {AwardsContext === MuddyAwardsStates.NOMINATIONS && (
                          <>
                            <p>
                              <strong>
                                ROUND 1: NOMINATE YOUR FAVOURITE LOCAL
                                BUSINESSES (20 FEB - 14 MARCH 2024){' '}
                              </strong>
                            </p>
                            <p>
                              Nominate the businesses that you love locally
                              across 21 lifestyle categories. You can put
                              forward one business per category per email
                              address. If you have your own business you can
                              nominate yourself (head to the{' '}
                              <Link to={`/awards/assets/`}>ASSETS</Link> tab for
                              downloadable social media assets and more).{' '}
                            </p>
                          </>
                        )}
                        <p>
                          <strong>
                            REGIONAL FINALS (3 APRIL – 18 APRIL 2024)
                          </strong>
                        </p>
                        <p>
                          Public voting closed at 1pm, 18 APRIL 2024. We are
                          counting and verifying the votes and Regional Winners
                          will be announced on 2 MAY 2024.
                        </p>
                        <p>
                          <strong>
                            NATIONAL FINALS (3 JULY- 11 JULY 2024)
                          </strong>
                        </p>
                        <p>
                          Regional Winners from every category will
                          automatically go through to our prestigious National
                          Finals. The Muddy Stilettos Editors will judge who is
                          the ‘Best of the Best&apos; in each category,
                          announcing the National Winners on 11 JULY 2024.
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <p>&nbsp;</p>
          <h2 style={{ color: 'rgb(231, 44, 94)' }}>WANT TO KNOW MORE?</h2>
          <p>Watch to find out why we run the Muddy Stilettos Awards.</p>
          <div className={LeaderStyles.YouTubeEmbed}>
            <iframe
              src="https://www.youtube.com/embed/w2fBvfIXvvc?si=-9433bGkG04YixbP"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          {/* <p>&nbsp;</p>
          <h2 style={{ color: 'rgb(231, 44, 94)' }}>WIN!</h2>
          <p>
            We&apos;re delighted to be offering a £5,000 cash prize for a
            UK-based business, courtesy of our National sponsor, Tide banking.
            You don&apos;t need to be a business participating in the Muddy
            Awards to enter, but you must be registered at Companies House. For{' '}
            <Link to={`/awards/win`}>more information</Link> and full T&amp;Cs{' '}
            <a
              href={`https://www.tide.co/terms/muddystilettosawards/`}
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            .
          </p> */}
          {/* <p>Good luck everyone!</p>
          <p>
            <strong>{countyInfo?.editorName ?? 'Hero Brown'}</strong>
          </p>
          <p>
            <em>{countyInfo?.editorJobTitle ?? 'Founder, Editor-in-Chief'}</em>
          </p> */}
          {AwardsContext === MuddyAwardsStates.NOMINATIONS && (
            <>
              <p>&nbsp;</p>
              <p>
                <Link
                  className={LeaderStyles.Button}
                  style={{ width: 'fit-content' }}
                  to={
                    generalSettings.siteId === 1
                      ? `/awards/counties`
                      : `/awards/categories`
                  }
                >
                  Click here to start nominating
                </Link>
              </p>
              <p>&nbsp;</p>
            </>
          )}
          {AwardsContext === MuddyAwardsStates.VOTING && (
            <p>
              <Link
                className={LeaderStyles.Button}
                style={{ width: 'fit-content' }}
                to={
                  generalSettings.siteId === 1
                    ? `/awards/counties`
                    : `/awards/categories`
                }
              >
                Click here to start voting
              </Link>
            </p>
          )}
          <p>
            <strong>
              <Link to={'/awards/about-us/'}>ABOUT US</Link>
            </strong>
          </p>

          <p>
            So you know about the Muddy Stilettos Awards - but it all started
            with our{' '}
            <Link to="/awards/about-us/">award-winning lifestyle site</Link>! If
            you want to know the best of everything near you, from boutique
            hotels to gastropubs, family days out to schools (and everything
            else in between) head straight to your{' '}
            <Link to={`/`}>local Muddy website</Link> and sign up for our 
            <Link to="/sign-up/">free newsletters</Link> to be the first to
            know.
          </p>
        </Content>
      </div>
      <div>
        {trendingCat && (
          <BlocksWrappers
            slug={trendingCat?.slug || ''}
            title={trendingCat?.title || ''}
            index={1}
            awardsBlock={true}
          />
        )}
      </div>
      <CTARow order={4}>
        <h3>Get Muddy</h3>
        <p>
          Sign up for our inside line on the most brilliant things happening
          near you
        </p>
        <p>
          <Link to="/sign-up">Sign up for our free newsletter</Link>
        </p>
      </CTARow>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </div>
  )
}

export default AwardsIntro
